.side-dash {
  background: #ce2030;
  color: #e9e9e9;
  padding: 10px;
}
ul.sidebarList li{
  margin-top: 5px;
  margin-bottom: 10px;
}
.sidebarWrapper {
  padding: 10px;
}
.slim-side{
  width: 100px;
}
.full-side {
  width: 200px;
}
.sidebarMenu ul li a:hover {
  color: #303030;
}
.alerter {
 padding:10px;
}