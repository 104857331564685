.numFeed {
    font-size: 69px;
    font-weight: 900;
}

h3.hdi-title {
    color: #908e8ee3;
    margin-bottom: 4px;
    margin-top: 15px;
}
.home-dash-item {
    min-width: 150px;
}

.hc.hc-content-outer {
    padding: 7px;
}
.profile-cont-inn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.hc-content-inn h4 {
    font-size: 15px;
    margin-bottom: 3px;
}
.ld-cont {
    font-size: 12px;
}
.cont-user {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px dotted #eeeeee;
    margin-bottom: 3px;
}
.scroll-users {
    height: 200px;
    margin-top: 15px;
}
.gnum {
    font-size: 20px;
    font-weight: 200;
    color: gray;
}