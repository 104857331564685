@media screen and (max-width: 1350px) {
    .content-nav {
       margin-right:6%; 
    }
}
@media screen and (max-width: 1250px) {
    li .content-profile .titling {
        font-size: 15px;
        width: 140px;
    }

    .inner-display.grid-layout img.disp-image {
        height: 100px;
    }
    .inner-display.grid-layout .pc-lbl.pc-image {
        height: 120px;
    }
    .grid-layout {
        grid-auto-rows: 205px;
    }
}
@media screen and (max-width: 1099px) {
    .flex-mg-space {
        width: 330px;
    }
    .grid-layout {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (max-width: 1000px) {
    .main-dash-content {
        width: 78%;
    }
}
@media screen and (max-width: 950px) {
    .grid-layout {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 760px) {
    span.name-Item {
        display: none;
    }
    .bs-side.full-side{
        width: 30px;
    }
    .main-dash-content {
        width: 94%;
    }
    .flexme.flexon-mobile {
        display: block;
    }
    img.sizeex {
        width: 250px;
    }
    .logo-spark{
        display: none;
    }
    .logo-mobile{
        display: block;
    }
    .grid-layout {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .grid-layout {
        grid-template-columns: repeat(1, 1fr);
    }

    .main-content-arena {
        padding: 10px;
    }
    .mobOff .flexme {
        display: block;
    }
    .ct-perc {
        background: #000;
        border-top-left-radius: 35%;
    }

    .mobOff .flx-50 {
        width: 100%;
    }
    .card {
        padding: 20px;
    }
    .vari-flex {
        padding: 4px;
        width: 98%;
    }
    .but-small button {
        width: 80%;
        margin-bottom: 15px;
        padding: 10px;
    }

    img.previewItem {
        height: 260px;
        margin-bottom: 30px;
        width: 260px;
    }
    .cat-boxer.add-photo-rad {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }
    .card-body {
        padding: 0px;
    }
    .grid-catas, .grid-layout {
        display: block;
    }
    .grid-catas .mp-block-item {
        margin-bottom: 17px;
        padding: 20px;
    }
    .table-header.flex.flexymobile {
        display: block !important;
    }
}