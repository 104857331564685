
.top-nav {
  background: #fff;
  padding: 15px;
  font-size: 11px;
  font-weight: 600;
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
}
.nav-bar {
  display: flex;
  justify-content: space-between;
}
.content-nav {
display: flex;
}
button.dropdown-toggle.btn.btn-success.profile-ps {
padding: 0px;
background: #fff;
}
.dropdown-menu.show {
margin-top: -5px;
padding-bottom: 0px;
}
.dropdown-menu {
background: rgb(255, 255, 255);
display: none;
border: 1px solid #e8e8e8;
border-radius: 10px;
}
.pdspace { 
padding:20px
}
.pdtpbt10 { 
padding:10px 0px
}
.list-nav-item {
border-bottom: 1px solid #e8e8e8;
}
button.btn-logout {
background: #2f2d2d;
width: 100%;
padding: 10px;
color: #fff;
border: none;
cursor: pointer;
}
button.btn-logout:hover {
background: #fff !important;
color: #2f2d2d;
}
.log-box {
padding: 20px 10px;
background: #ce2030;
}
.dsh-profile {
height: 30px;
width: 30px;
background-size: cover;
background-position: center;
border: 1px solid gray;
border-radius: 50%;
}
span.sp-item {
font-size: 20px;
padding-right: 10px;
}
.title-dash-name {
text-align: center;
  padding: 10px;
  font-size: 15px;
}
a.dropdown-item {
display: flex;
margin-bottom: 7px;
text-decoration: none;
color: #514444;
font-size: 12px;
font-weight: 400;
width: 210px;
}
button#dropdown-basic {
width: 35px;
height: 38px;
}
.no-approve-outer {
position: absolute;
background: #dcdcdccf;
width: 100vw;
left: 0;
top: 0px;
height: 100vh;
z-index: 1000;
}
.not-approved-box {
background: #fff;
width: 60vw;
margin: 8% auto 10px;
padding: 20px;
}
.log-box-mini {
padding: 40px;
width: 50%;
margin: auto;
}