.tab-dashboard-item {
    display: flex;
}

.side-dash-settings-nav {
    width: 20%;
    background: #fffaf9;
}

.side-dash-settings-nav .tabs {
    padding: 10px;
    color: #4b4b4b;
    cursor: pointer;
    font-size: 13px;
    border-bottom: 1px solid #f1f1f1;
}
.tabs.active-tabs {
    background: #eae1e1;
}
.tab-content-box .content-tab {
    padding: 30px;
    background: #fff;
    display: none;
    font-size: 12px;
}
.tab-content-box .content-tab.content-active {
    display: block;
}
.tab-content-box {
    width: 80%;
    background: #fff;
}
.copy-box h4 {
    color: #ce2030;
}
code {
    border: 3px solid #444444;
    padding: 5px 10px;
    font-size: 10px;
}
.copy-box {
    padding: 15px 0px;
}
.total-ct {
    padding: 10px;
    font-weight: 600;
}
img.proc.mgimageup{
    bottom: 40%;
}
.mini-title.md-tit {
    font-size: 12px;
    width: 210px;
}
.mssgeCont {
    background: #f6f6f6;
    padding: 10px;
    margin-top: 22px;
    font-weight: 100;
}
.col-items-display {
    font-size: 10px;
    color: gray;
}
.col-items-display span {
    padding: 2px 8px;
    margin-right: 2px;
    background: #e6e6e6;
}
.frm-data.excel-forms {
    border: 1px dashed #d8d8d8;
    padding: 10px;
}
.table-excell-display{
    height: 540px;
    overflow: scroll;
}
/* Progress Bar Start */
.progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 300px;
}
.progress-done {
	background: linear-gradient(to left, #ce2030, #FF9472);
	box-shadow: 0 3px 3px -5px #ce2030, 0 2px 5px #ce2030;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}
/* Progress Bar End */
.msg-processing-item {
    font-size: 10px;
    font-style: italic;
    padding: 20px;
    border: 1px dotted #eeeeee;
    margin-left: 11px;
    background: #fafafa;
    border-radius: 10px;
}